import styles from './styles.module.scss';

import React from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import qs from 'qs';
import PropTypes from 'prop-types';
import {isEmpty} from 'lodash/lang';
import classNames from 'classnames';

import {Table, UncontrolledTooltip} from 'reactstrap';
import {IconFA} from 'components/Icons';
import {ContentPagination} from 'components/Contents';
import {LoadingSpinner} from 'components/Loading';
import {isObject, omit, snakeCase} from 'lodash';
import cx from 'classnames';

import {ContentTableLimit} from 'components/Contents';
import useFilter from 'hooks/useFilter';

const ContentTableTooltip = ({id, children, placement = 'top'}) => {
  return (
    <span>
      <span id={`tooltip-${id}`} className="text-secondary cursor-pointer">
        <IconFA
          name="info-circle"
          className="text-secondary"
          id="TableTooltip"
        />
      </span>

      <UncontrolledTooltip
        placement={placement}
        target={`tooltip-${id}`}
        modifiers={{preventOverflow: {boundariesElement: 'window'}}} // prevents the placement issue when nested in a container with overflow auto
      >
        {children}
      </UncontrolledTooltip>
    </span>
  );
};

// we used functional because withRouter returns staticContext props that triggers warning on console
function ContentTableSorting(props) {
  const history = useHistory();
  const location = useLocation();

  function handleChangeSort() {
    const {value} = props;
    const {search, pathname} = location;
    const {order, order_by} = qs.parse(search, {ignoreQueryPrefix: true});

    const query = omit(qs.parse(search, {ignoreQueryPrefix: true}), [
      'order',
      'order_by',
    ]);

    // reset pagination
    delete query.offset;

    if (value !== order_by) {
      // always start at DESC <-- before
      // will replace to ASC since the default load from the data starts with DESC
      history.replace({
        pathname,
        search: qs.stringify({order: 'ASC', order_by: value, ...query}),
      });
      return;
    }

    if (value === order_by && order === 'ASC') {
      history.replace({
        pathname,
        search: qs.stringify({order: 'DESC', order_by: value, ...query}),
      });
      return;
    }

    if (value === order_by && order === 'DESC') {
      history.replace({
        pathname,
        search: qs.stringify({...query}),
      });
      return;
    }
  }

  const {label, value, tooltip, ...rest} = props;
  const {search} = location;
  const {order, order_by} = qs.parse(search, {ignoreQueryPrefix: true});
  const isOrdered = order_by === value;
  let icon;

  switch (order) {
    case 'ASC':
      icon = 'sort-up';
      break;
    case 'DESC':
      icon = 'sort-down';
      break;
    default:
      icon = 'sort';
  }

  return (
    <th onClick={() => handleChangeSort()} {...rest}>
      {!!tooltip && (
        <ContentTableTooltip id={value}>{tooltip}</ContentTableTooltip>
      )}
      <span>{label}</span>
      {/* Cheat since there is no duotone in react fontawesome we just used z index to make it icon stack to each other */}

      <span className="position-relative">
        {isOrdered && (
          <IconFA name="sort" className={styles['content-table-sorting']} />
        )}
        <IconFA
          name={isOrdered ? icon : 'sort'}
          className={cx(styles['content-table-sorting'], {
            [styles['content-table-sorting--active']]: isOrdered,
          })}
        />
      </span>
    </th>
  );
}

const ContentTableSortingStateful = (props) => {
  const {label, value, tooltip, meta, onChangeSort, ...rest} = props;
  const {order, order_by} = meta || {};
  const isOrdered = order_by === value;

  let icon;

  switch (order) {
    case 'ASC':
      icon = 'sort-up';
      break;
    case 'DESC':
      icon = 'sort-down';
      break;
    default:
      icon = 'sort';
  }

  const handleChangeSort = () => {
    if (!isOrdered) {
      onChangeSort('ASC');
      return;
    }

    if (isOrdered && order === 'ASC') {
      onChangeSort('DESC');
      return;
    }

    if (isOrdered && order === 'DESC') {
      onChangeSort();
      return;
    }
  };

  return (
    <th {...rest}>
      <div className="d-flex align-items-center">
        {!!tooltip && (
          <ContentTableTooltip id={value}>{tooltip}</ContentTableTooltip>
        )}
        <span>{label}</span>

        <div
          className={styles['sort-stateful__container']}
          onClick={() => handleChangeSort()}
        >
          <IconFA name="sort" className={styles['sort-stateful__icon']} />
          <IconFA
            name={isOrdered ? icon : 'sort'}
            className={
              isOrdered ? styles['sort-stateful__icon--active'] : 'd-none'
            }
          />
        </div>
      </div>
    </th>
  );
};

// Creating this function since we cannot directly decalare hooks inside the class component
function NumberLimiterComponent({value, total}) {
  const {updateFilter} = useFilter();

  return (
    <ContentTableLimit
      value={parseInt(value) || 0}
      total={parseInt(total) || 0}
      onChange={(limit) => updateFilter({offset: 0, limit: limit})}
    />
  );
}

// usage for 'head' props head={['column 1', 'column 2', 'column 3]}
// usage for 'head' props head={[{label: 'label 1', value: 'value 1', ...}]}

export default class ContentTable extends React.Component {
  static propTypes = {
    head: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
          tooltip: PropTypes.string,
          className: PropTypes.string,
        }),
      ])
    ),
    emptyMessage: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
  };

  static defaultProps = {
    meta: {},
    // Table theme options
    borderless: true,
    striped: false,
    hover: true,
    responsive: true,
    pagination: false,
    minHeight: 300,
    emptyMessage: (
      <span>
        <IconFA name="search" /> No Results Found
      </span>
    ),
  };

  render() {
    const {
      hover,
      head,
      className,
      pagination,
      dataLimiter,
      isFetching,
      meta,
      minHeight,
      children,
      emptyMessage,
      loadMore,
      ...rest
    } = this.props;

    return (
      <React.Fragment>
        <Table
          hover={hover && !isEmpty(children)}
          className={classNames(styles['content-table'], className)}
          {...rest}
        >
          {head && (
            <thead>
              <tr>
                {head
                  .filter((item) => !!item)
                  .map((item, index) => {
                    const isFilter =
                      isObject(item) &&
                      item.hasOwnProperty('label') &&
                      item.hasOwnProperty('value');

                    if (isFilter) {
                      const {onChangeSort, ...rest} = item;

                      return !!onChangeSort ? (
                        <ContentTableSortingStateful
                          key={index}
                          meta={meta}
                          onChangeSort={onChangeSort}
                          {...rest}
                        />
                      ) : (
                        <ContentTableSorting key={index} {...rest} />
                      );
                    }

                    const hasTooltip =
                      isObject(item) && item.hasOwnProperty('tooltip');

                    if (hasTooltip) {
                      const {tooltip, label, ...rest} = item;
                      return (
                        <th key={index} {...rest}>
                          <ContentTableTooltip id={snakeCase(tooltip)}>
                            {tooltip}
                          </ContentTableTooltip>
                          {label}
                        </th>
                      );
                    }

                    const hasClassName =
                      isObject(item) && item.hasOwnProperty('className');
                    if (hasClassName) {
                      const {label, className} = item;

                      return (
                        <th key={index} className={className}>
                          {label}
                        </th>
                      );
                    }

                    return <th key={index}>{item}</th>;
                  })}
              </tr>
            </thead>
          )}

          <tbody style={{minHeight: '300px'}}>
            {isFetching ? (
              <tr>
                <td
                  align="center"
                  colSpan={head.length}
                  height={minHeight}
                  style={{verticalAlign: 'middle'}}
                >
                  <LoadingSpinner />
                </td>
              </tr>
            ) : !isEmpty(children) ? (
              children
            ) : (
              <tr>
                <td
                  align="center"
                  colSpan={head.length}
                  height={minHeight}
                  style={{verticalAlign: 'middle'}}
                >
                  {emptyMessage}
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        {!isFetching && (
          <div className="d-flex justify-content-between align-items-center mb-3 mx-3">
            <div>
              {dataLimiter && meta.total_rows >= 20 && (
                <NumberLimiterComponent
                  value={meta.limit}
                  total={meta.total_rows}
                />
              )}
            </div>

            {pagination && meta.total_pages > 1 && (
              <ContentPagination totalPages={meta.total_pages || 1} />
            )}
          </div>
        )}

        {loadMore}
      </React.Fragment>
    );
  }
}
