import styles from './styles.module.scss';

import React, {useState, useContext, useEffect} from 'react';
import {useLocation, withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import {debounce} from 'lodash/function';
import Cookies from 'utils/cookies';
import {alertByError} from 'utils/alerts';
import {Button, Col, Row} from 'reactstrap';

import {getSearchSuggestCompany, getDashboardSearchLimit} from 'api/search';
import {AuthContext} from 'contexts/AuthContext';
import {SearchAsyncBase} from 'components/Forms/FormBases';
import {IAF_MAIN_URL} from 'configs/Environment';
import urljoin from 'url-join';
import {removePrefix} from 'utils/string';
import {MDB_PREFIX, VERIFIED_IMAGE} from 'configs';
import {ENABLE_SUBSCRIPTION} from 'configs/enable-features';
import {components} from 'react-select';
import {ImageFlag} from 'components/Images';
import {getCountryCodeById} from 'utils/locales';

const defaultProps = {
  name: 'company_id',
  isClearable: true,
  defaultOptions: false,
  verifyCaptcha: () => {},
};

function FlagOption(props) {
  const {company_name, countries_id} = props.data || {};

  const countryCode = getCountryCodeById(countries_id);
  return (
    <components.Option {...props}>
      <div className="d-flex align-items-center flex-row">
        <ImageFlag code={countryCode} className="mr-1" />
        <span>{company_name}</span>
      </div>
    </components.Option>
  );
}

function SearchSelectCE(props) {
  const {isAuth, isMainAccount} = useContext(AuthContext);

  const {
    history,
    verifyCaptcha,
    name,
    selected,
    value,
    onInputChange,
    onSelect,
    customOptionsMessage,
    onChange,
    callback,
    ...rest
  } = props;
  const location = useLocation();
  const [keyword, setKeyword] = useState('');
  const [data, setData] = useState(true);

  useEffect(() => {
    isAuth && getLimit();
  }, [location]);

  async function getLimit() {
    try {
      const {data} = await getDashboardSearchLimit(
        isMainAccount ? {type: 'cert'} : {}
      );
      setData(data);

      if (callback) callback(data);
    } catch (error) {
      alertByError(error);
    }
  }

  async function loadOptions(value, callback) {
    setKeyword(value);

    let result = [];

    try {
      const {data} = await getSearchSuggestCompany({query: value});

      result = data.map((item) => {
        let obj = {};
        for (const [key, value] of Object.entries(item)) {
          obj = {company_id: key, company_name: value.company_name, ...value};
        }
        return obj;
      });
    } catch (e) {
      if (e.status === 429) {
        Cookies.captchaToken = '';
        await alertByError(e);
      } else {
        if (e.code === 'invalid_captcha_token') verifyCaptcha();
      }
    }

    if (onInputChange) onInputChange(value, result);
    return callback(result);
  }

  async function handleChange(selected) {
    isAuth && getLimit();
    if (selected) {
      const {company_id, company_name} = selected;
      const mdbCeId = removePrefix(company_id, MDB_PREFIX.ce);

      if (!isMainAccount) {
        window.location.replace(
          urljoin(IAF_MAIN_URL, `/certified-entity/${mdbCeId}`)
        );
      } else {
        history.push({
          pathname: `/certified-entity/${mdbCeId}`,
          state: {keyword: keyword, company_name: company_name},
        });
      }

      if (onSelect) onSelect(selected, keyword);
    }
  }

  const noOptionsMessage = ({inputValue}) => {
    return (
      <div className={styles['search__not-found']}>
        {inputValue ? (
          <>
            <div className="mb-2">
              Can’t find the company, you’re looking for?
            </div>
            <div>
              <Link to="/enquiry">Information request form</Link>
            </div>
          </>
        ) : (
          <>
            {isAuth ? (
              <>
                {customOptionsMessage ? (
                  customOptionsMessage(data)
                ) : (
                  <Row>
                    <Col lg={1}>
                      <i
                        className="fa fa-exclamation-circle fa-4x"
                        style={{color: 'orange'}}
                      />
                    </Col>
                    <Col>
                      <div>
                        <b>
                          {data.try} / {data.max} search limit used.
                        </b>
                        <br />
                        Want to increase your search limit? Contact us at,
                        <br />
                        <a href="mailto: support@iafcertsearch.org">
                          <b>IAF CertSearch support.</b>
                        </a>
                      </div>
                    </Col>
                  </Row>
                )}
              </>
            ) : ENABLE_SUBSCRIPTION ? (
              <div>
                <span>Provide company name to search</span>
                <hr />
                <Row className="d-flex flex-row justify-content-center align-items-center text-left">
                  <Col xs="auto">
                    <img src={VERIFIED_IMAGE} alt="try" />
                  </Col>

                  <Col>
                    <h4 className="mb-0">Try Bulk Verification</h4>
                    <span>
                      Streamline your certificate verification process
                      effortlessly by verifying multiple certificates
                      simultaneously.
                    </span>
                  </Col>
                  <Col xs={12} md="auto" className="text-center mt-2 mt-lg-0">
                    <Button
                      tag={Link}
                      to="/register"
                      color="outline-primary"
                      className="mx-3"
                    >
                      Sign Up
                    </Button>
                  </Col>
                </Row>
              </div>
            ) : (
              'Provide company name to search...'
            )}
          </>
        )}
      </div>
    );
  };

  const selectOptions = {
    loadOptions: debounce(loadOptions, 200),
    onChange: (selected) =>
      onChange ? onChange(selected, keyword) : handleChange(selected),
    getOptionLabel: (option) => option.company_name,
    getOptionValue: (option) => option.company_id,
    placeholder: 'Search certification by company name',
    noOptionsMessage: (options) =>
      customOptionsMessage ? customOptionsMessage() : noOptionsMessage(options),
    components: {Option: FlagOption},
    ...rest,
  };

  return <SearchAsyncBase {...selectOptions} />;
}

SearchSelectCE.defaultProps = defaultProps;

export default withRouter(SearchSelectCE);
